export default {
  styleOverrides: {
    gutterBottom: {
      marginBottom: 8,
    },
    subtitle2: {
      fontWeight: 500,
    },
  },
};
