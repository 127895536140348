export default {
  styleOverrides: {
    root: {
      padding: 0,
      '&:hover': {
        background: 'none',
      },
    },
  },
};
