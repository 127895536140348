export default {
  styleOverrides: {
    root: {
      minHeight: '42px',
      '& #outlined-fieldset-helper-text': {
        marginLeft: '20px',
      },
    },
  },
};
